import { TmapApp } from './TmapApp'

const tmapApp = new TmapApp()

if (typeof window !== 'undefined') {
  tmapApp.init()
}

export * from './types'
export { parseUserAgent } from './env'
export { tmapApp as TmapApp }
