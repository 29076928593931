import gte from 'semver/functions/gte'
import { TmapApp } from './TmapApp'
import { callCustomUrl, log } from './utils'

export abstract class BaseInterfaceImpl {
  constructor(protected tmapApp: TmapApp) {
  }

  protected selectTimeout(targetAppVersion: string, targetTimeout: number | null, defaultTimeout: number | null = 300) {
    return gte(this.tmapApp.env.appVersion, targetAppVersion) ? targetTimeout : defaultTimeout
  }

  protected get env() {
    return this.tmapApp.env
  }

  protected log(...args: Parameters<typeof log>) {
    return this.tmapApp.utils.log.apply(this.tmapApp, args)
  }

  protected callCustomUrl(...args: Parameters<typeof callCustomUrl>) {
    return this.tmapApp.utils.callCustomUrl.apply(this.tmapApp, args)
  }

}
