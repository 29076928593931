import trim from 'lodash/trim'
import coerce from 'semver/functions/coerce'

type MethodNames = Extract<keyof Env, 'setEnv' | 'print'>
type FieldNames = Exclude<keyof Env, MethodNames>
type FieldProps = Omit<Env, MethodNames>

class Env {
  appVersion = ''
  appName = ''
  userAgent = ''
  os = ''
  device = ''
  isIOS = false
  isAndroid = false
  isInApp = false

  constructor(env: Partial<FieldProps>) {
    Object.assign(this, env)
  }

  set<Key extends FieldNames>(key: Key, v: this[Key]) {
    this[key] = v
    return this
  }

  print() {
    console.log(JSON.stringify(this, null, 2))
  }
}

function parseUserAgent(userAgent: string) {
  const env: Partial<Env> = {}
  const userAgentLower = userAgent.toLowerCase()
  const device = userAgent.replace(/.*?\((.+?)\).*/, '$1')
  const appName = 'Tmap'

  env.appVersion = '0.0.0'
  env.appName = appName
  env.userAgent = userAgent
  env.isInApp = userAgentLower.indexOf(env.appName.toLowerCase()) > -1
  env.device = trim(device.split(';').slice(1).join(';'))

  if (env.isInApp) {
    const inAppSuffixIndex = userAgentLower.indexOf(appName.toLowerCase())
    const inAppSuffix = userAgent.substring(inAppSuffixIndex)
    // 앱에서 웹뷰 userAgent에 아래 형식으로 접미사를 붙인다.
    // * 안드로이드 : Tmap/SK/Android/8.0.0/ 또는 Tmap/KU/Android/8.12.1/
    // * iOS : Tmap/iOS/6.6.0
    const parts = trim(inAppSuffix, '/').split('/')

    if (inAppSuffix.toLowerCase().indexOf('ios') !== -1) {
      env.isIOS = true
    }
    if (inAppSuffix.toLowerCase().indexOf('android') !== -1) {
      env.isAndroid = true
    }

    const os = parts[env.isIOS ? 1 : 2] || ''
    const appVersion = coerce(parts[env.isIOS ? 2 : 3])
    if (appVersion) {
      env.appVersion = appVersion.version
    }
    env.os = os
  } else {
    env.os = device.split(';')[0]
    env.isIOS = /ipad|iphone/i.test(userAgent)
    env.isAndroid = /android/i.test(userAgent)
  }

  return new Env(env)
}

export { parseUserAgent, Env }
